*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Montserrat;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.z-\[100\] {
  z-index: 100;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.h-0 {
  height: 0;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[40\%\] {
  height: 40%;
}

.h-\[45\%\] {
  height: 45%;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-\[60\%\] {
  height: 60%;
}

.h-\[6px\] {
  height: 6px;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.w-0 {
  width: 0;
}

.w-4 {
  width: 1rem;
}

.w-7 {
  width: 1.75rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[6px\] {
  width: 6px;
}

.w-full {
  width: 100%;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.flex-1 {
  flex: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.self-start {
  align-self: flex-start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-none {
  border-style: none;
}

.border-\[\#393A3D\] {
  --tw-border-opacity: 1;
  border-color: rgb(57 58 61 / var(--tw-border-opacity));
}

.bg-\[\#00000040\] {
  background-color: #00000040;
}

.bg-\[\#496465\] {
  --tw-bg-opacity: 1;
  background-color: rgb(73 100 101 / var(--tw-bg-opacity));
}

.bg-\[\#F2F4F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 244 247 / var(--tw-bg-opacity));
}

.bg-\[\#F6F5F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 245 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.font-\[\'Montserrat\'\] {
  font-family: Montserrat;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[4vw\] {
  font-size: 4vw;
}

.text-\[50px\] {
  font-size: 50px;
}

.text-\[60px\] {
  font-size: 60px;
}

.text-\[8\.5vw\] {
  font-size: 8.5vw;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.text-\[\#393A3D\] {
  --tw-text-opacity: 1;
  color: rgb(57 58 61 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-700 {
  transition-duration: .7s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#left-container {
  background-color: #496465;
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

#right-container {
  background-color: #f6f5f5;
  position: absolute;
  top: 0;
  right: 0;
}

#menu-container {
  width: inherit;
  pointer-events: none;
  z-index: 2;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  display: flex;
  position: fixed;
  bottom: 0;
}

#menu {
  z-index: 2;
  pointer-events: all;
  background-color: #f6f5f5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  display: flex;
}

#menu a {
  color: #000;
  text-decoration: none;
}

#progression-bar-container {
  flex-direction: row;
  width: 100%;
  display: flex;
}

#pgogression-bar-active {
  background-color: #000;
  width: 0%;
  height: 1px;
}

#pgogression-bar-unactive {
  opacity: .1;
  background-color: #000;
  width: 100%;
  height: 1px;
}

.first-page-title {
  font-family: Inter, sans-serif;
  font-size: 6vw;
  font-weight: 300;
}

.first-page-title:first-child {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.first-page-title:nth-child(2) {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.first-page-title:nth-child(3) {
  position: absolute;
  top: 40%;
  left: 74%;
  transform: translate(0%, -50%);
}

.first-page-title:nth-child(4) {
  position: absolute;
  top: 55%;
  left: 70%;
  transform: translate(-50%, -50%);
}

.focus\:border-none:focus {
  border-style: none;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (width >= 640px) {
  .sm\:text-\[14px\] {
    font-size: 14px;
  }

  .sm\:text-\[60px\] {
    font-size: 60px;
  }
}

@media (width >= 768px) {
  .md\:z-\[3\] {
    z-index: 3;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:-ml-\[15vw\] {
    margin-left: -15vw;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:h-\[30vh\] {
    height: 30vh;
  }

  .md\:h-\[40\%\] {
    height: 40%;
  }

  .md\:h-\[52vh\] {
    height: 52vh;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-\[35vw\] {
    width: 35vw;
  }

  .md\:w-\[40\%\] {
    width: 40%;
  }

  .md\:w-\[50\%\] {
    width: 50%;
  }

  .md\:w-\[60\%\] {
    width: 60%;
  }

  .md\:w-\[75vw\] {
    width: 75vw;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:text-\[100px\] {
    font-size: 100px;
  }

  .md\:text-\[16px\] {
    font-size: 16px;
  }

  .md\:text-\[40px\] {
    font-size: 40px;
  }

  .md\:text-\[60px\] {
    font-size: 60px;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

/*# sourceMappingURL=index.38218e2c.css.map */
