@tailwind base;
@tailwind components;
@tailwind utilities;

#left-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 0vw;
    height: 100vh;
    /* background-color: #E8D5BC; */
    background-color: #496465;
}


#right-container {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #F6F5F5;
}

#menu-container {
    position: fixed;
    bottom: 0;
    height: 100vh;
    width: inherit;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
    z-index: 2;
}

#menu {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F5F5;
    z-index: 2;
    pointer-events: all;

}

#menu a {
    text-decoration: none;
    color: black;
}


#progression-bar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#pgogression-bar-active {
    height: 1px;
    background-color: black;
    width: 0%;
}

#pgogression-bar-unactive {
    height: 1px;
    background-color: black;
    width: 100%;
    opacity: 10%;
}

.first-page-title {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 6vw;
}

.first-page-title:nth-child(1) {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.first-page-title:nth-child(2) {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.first-page-title:nth-child(3) {
    position: absolute;
    top: 40%;
    left: 74%;
    transform: translate(0%, -50%);
}

.first-page-title:nth-child(4) {
    position: absolute;
    top: 55%;
    left: 70%;
    transform: translate(-50%, -50%);
}